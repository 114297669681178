import React from "react";
import Slider from "@material-ui/core/Slider";
import Logo from "../assets/e-nundation-gs-logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faWater,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import {ReactComponent as ModeCrise} from "../assets/icons/ico_modecrise.svg";
import {ReactComponent as Repeat} from "../assets/icons/ico_repeat.svg";
import {connect} from "react-redux";
import * as actionCreators from "../store/actions";
import './Header.css';
import * as debitDict from '../constants/dict_debits.json';
import config from "../config"

const apiServer = config.apiPath;

const ScaleEntry = ({scaleMarksList}) => (
  <>
    {scaleMarksList.map((scaleMark) => (
      <div
        className="header_slider_input_scale"
        style={scaleMark.style}
        key={scaleMark.value}
      >
        {scaleMark.label}
      </div>
    ))}
  </>
);

const scaleMarksList = [];

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.scenarioDepart
    };
    this.updateSliderValue = this.updateSliderValue.bind(this);
    this.updateRealTime = this.updateRealTime.bind(this);
    this.generateScaleMarksList();
  }


  generateScaleMarksList() {
    let uniteMesure = this.props.typeMesureDebit ? " m³/s" : " m";
    const scale = 20;
    const max = this.props.nbScenario - 1;
    const min = 0;
    const padding = 0;

    for (let index = min; index < max; index += scale) {
      const position = Math.round((index / (max - min)) * 100);

      if (position < 100) {
        scaleMarksList.push({
          value: index,
          style: {left: `calc(${position}% + ${padding}px)`},
          // label: `${this.props.labels[index]} ${this.props.uniteMesure}`,
          label: `${this.props.labels[index]} ${uniteMesure}`,
          // label: this.props.labels[index] + uniteMesure,
        });
      }
    }
  }

  updateRealTime(value) {

    // Activate button
    this.props.onActiveBouton(value.target.getAttribute("id"));

    let debit = value.target.getAttribute("data");

    let scenario = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(debit)];
    if (scenario === undefined) {
      scenario = 0;
    }

    // Dispatch waterLevelEvent
    this.setState({value: parseInt(scenario, 10)});
    const waterLevelEvent = new CustomEvent("waterLevel", {
      detail: {scenario: parseInt(scenario, 10), debit: debit, color: this.SetColor(debit)},
    });
    document.dispatchEvent(waterLevelEvent);

    // Toggle temps reel on
    this.props.onTempsReelToggle("on");


  }

  updateSliderValue(value) {


    // Activate button
    this.props.onActiveBouton(value.target.getAttribute("id"));

    let debit = value.target.getAttribute("data");


    let scenario = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(debit)];
    if (scenario === undefined) {
      scenario = 0;
    }
    // Dispatch waterLevelEvent
    this.setState({value: parseInt(scenario, 10)});
    const waterLevelEvent = new CustomEvent("waterLevel", {
      detail: {scenario: parseInt(scenario, 10), debit: debit, color: this.SetColor(debit)},
    });
    document.dispatchEvent(waterLevelEvent);

    // Toggle temps reel off
    this.props.onTempsReelToggle("off");

  }


  EmitSliderValue(value) {

    let debit = this.props.labels[this.state.value];
    this.updateLabelAndColorSlider(debit);
    // Deactivate all buttons
    this.props.onActiveBouton("");

    // Dispatch waterLevelEvent
    this.setState({value: value});
    const waterLevelEvent = new CustomEvent("waterLevel", {
      detail: {scenario: parseInt(value, 10), debit: debit, color: this.SetColor(debit)},
    });
    document.dispatchEvent(waterLevelEvent);

    // Toggle temps reel off
    this.props.onTempsReelToggle("off");
  }


  updateLabelAndColorSlider(debit) {

    document.getElementById('slider-flow').lastChild.style.border = '3px solid ' + this.SetColor(debit);
    document.getElementById('slider-flow').lastChild.lastChild.lastChild.style.color = this.SetColor(debit);
    document.getElementById('slider-flow').lastChild.lastChild.lastChild.lastChild.innerHTML = debit + " m³/s";

  }


  FindScenario(debit) {
    return Math.ceil(debit / 100) * 100
  }

  SetColor(debit) {
    if (this.props.setApp === "SA_M") {
      if (debit < 4500) {
        return '#006600'
      } else if (debit < 5300) {
        return '#ff6600'
      } else {
        return '#c00000'
      }
    } else if (this.props.setApp === "RI_M") {
      if (debit < 3900) {
        return '#548335'
      } else if (debit < 4600) {
        return '#DEA900'
      } else if (debit < 7200) {
        return '#EE5D30'
      } else if (debit < 9000) {
        return '#EA0001'
      } else {
        return '#990134'
      }
    }
  }


// componentWillMount() {
  componentDidMount() {
    this.updateLabelAndColorSlider(this.props.labels[this.state.value]);
    // données en temps réel dans la BD
    // fetch(`${apiServer}/api/average-daily-flow?code=RI_M`)
    //   .then(response => response.json())
    //   .then((data) => {
    // let hier = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(data["RI_M"]["yesterday"])];
    // let reel = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(data["RI_M"]["real_time"])];
    // let lastUpdate = data["RI_M"]["last_updated"];
    // this.props.onSetBtnHier(hier);
    // this.props.onSetBtnReel(reel);
    // this.props.onSetValueHier(data["RI_M"]["yesterday"]);
    // this.props.onSetValueReel(data["RI_M"]["real_time"]);
    // this.props.onSetLastUpdate(lastUpdate);
    // }).catch(err => console.error(err));

    let hier = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(5256)];
    let reel = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(5736)];
    // let lastUpdate = data["RI_M"]["last_updated"];
    this.props.onSetBtnHier(hier);
    this.props.onSetBtnReel(reel);
    this.props.onSetValueHier(5256);
    this.props.onSetValueReel(5736);
    // this.props.onSetLastUpdate(lastUpdate);
    // données dans S3
    fetch('https://previsions-hydrometeo.s3.ca-central-1.amazonaws.com/previsions_hm.json')  // Production
      // fetch('https://previsions-geosapiens.s3.ca-central-1.amazonaws.com/previsions_hm_test.json')  // Dev
      .then(res => res.json())
      .then((debitExtrait) => {
        for (const [river, debits] of Object.entries(debitExtrait)) {
          // let j1 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(debits["j1"])];
          // let j2 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(debits["j2"])];
          // let j3 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(debits["j3"])];
          // let j4 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(debits["j4"])];
          // let j5 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(debits["j5"])];
          // let j6 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(debits["j6"])];
          // let j7 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(debits["j7"])];
          // this.props.onSetBtnJ1(j1);
          // this.props.onSetBtnJ2(j2);
          // this.props.onSetBtnJ3(j3);
          // this.props.onSetBtnJ4(j4);
          // this.props.onSetBtnJ5(j5);
          // this.props.onSetBtnJ6(j6);
          // this.props.onSetBtnJ7(j7);
          // this.props.onSetValueJ1(debits["j1"]);
          // this.props.onSetValueJ2(debits["j2"]);
          // this.props.onSetValueJ3(debits["j3"]);
          // this.props.onSetValueJ4(debits["j4"]);
          // this.props.onSetValueJ5(debits["j5"]);
          // this.props.onSetValueJ6(debits["j6"]);
          // this.props.onSetValueJ7(debits["j7"]);

          let j1 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(5836)];
          let j2 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(6253)];
          let j3 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(6925)];
          let j4 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(7426)];
          let j5 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(8532)];
          let j6 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(9023)];
          let j7 = Object.entries(debitDict)[0][1][this.props.setApp][this.FindScenario(9563)];
          this.props.onSetBtnJ1(j1);
          this.props.onSetBtnJ2(j2);
          this.props.onSetBtnJ3(j3);
          this.props.onSetBtnJ4(j4);
          this.props.onSetBtnJ5(j5);
          this.props.onSetBtnJ6(j6);
          this.props.onSetBtnJ7(j7);
          this.props.onSetValueJ1(5836);
          this.props.onSetValueJ2(6253);
          this.props.onSetValueJ3(6925);
          this.props.onSetValueJ4(7426);
          this.props.onSetValueJ5(8532);
          this.props.onSetValueJ6(9023);
          this.props.onSetValueJ7(9563);

          // Set for specific apps
          // if (river === this.props.setApp) {
          // this.props.onSetBtnJ1(scenarios["j1"]);
          // this.props.onSetBtnJ2(scenarios["j2"]);
          // this.props.onSetBtnJ3(scenarios["j3"]);
          // this.props.onSetBtnJ4(scenarios["j4"]);
          // this.props.onSetBtnJ5(scenarios["j5"]);
          // this.props.onSetBtnJ6(scenarios["j6"]);
          // }
        }
      }).catch(err => console.error(err));
  }


  render() {
    // Change type of mesure
    let typeMesure = this.props.typeMesureDebit ? "Discharge" : "Water level";
    let uniteMesure = this.props.typeMesureDebit ? " m³/s" : " m";
    // Set last update time
    // let lastUpdate = this.props.setLastUpdate;
    // Set debit values
    let btnHier = this.props.setValueHier;
    let btnReel = this.props.setValueReel;
    let btnJ1;
    if (this.props.setValueReel > this.props.setValueJ1) {
      btnJ1 = this.props.setValueReel;
    } else {
      btnJ1 = this.props.setValueJ1;
    }
    let btnJ2 = this.props.setValueJ2;
    let btnJ3 = this.props.setValueJ3;
    let btnJ4 = this.props.setValueJ4;
    let btnJ5 = this.props.setValueJ5;
    let btnJ6 = this.props.setValueJ6;
    let btnJ7 = this.props.setValueJ7;

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const boutonsArray = new Array(8);
    for (let i = 0; i < boutonsArray.length; i++) {
      boutonsArray[i] = new Date();
      boutonsArray[i].setDate(new Date().getDate() + i - 1);
      boutonsArray[i] = months[boutonsArray[i].getMonth()] + " " + boutonsArray[i].getDate();
    }
    // const j1 = new Date();
    // j1.setDate(new Date().getDate());
    // const j1Label = j1.getDate() + " " + months[j1.getMonth()];

    return (
      <header className="header">
        <img className="header_logo" src={Logo} alt="logo"/>
        <div className="slider_container">
          <div className="slider_header_container">
            <div className="slider_header_wrapper">
              <div className="header_slider">
                                <span className="header_slider_text">
                                    <FontAwesomeIcon icon={faWater}/>{" "}
                                  <span className="typeMesureContainer">
                                        {typeMesure}
                                    </span>
                                 </span>
                <span className="header_slider_text_below">
                                    <Repeat/>{" "}
                  <span className="typeMesureContainer">
                                        {this.props.criseOptionsLabel.rec}
                    <br/>
                    {this.props.criseOptionsLabel.annees}
                                    </span>
                                </span>
              </div>
              <div className="slider_header_container_wrap">
                <ScaleEntry scaleMarksList={scaleMarksList}/>

                <Slider
                  id="slider-flow"
                  className="header_slider_input"
                  min={0}
                  max={this.props.nbScenario - 1}
                  step={1}
                  defaultValue={this.props.scenarioDepart}
                  value={this.state.value}
                  valueLabelFormat={`${this.props.labels[this.state.value]} ${uniteMesure}`}
                  // valueLabelFormat={`${labelCursor} ${uniteMesure}`}
                  valueLabelDisplay="on"
                  marks={this.props.yearMarkers}
                  onChange={(_, value) => this.EmitSliderValue(value)}
                />
              </div>
            </div>
            <div className="gestion_crise">
              <button
                id="btnCrise"
                className={this.props.toggleCrise ? "btnCriseTrue" : "btnCriseFalse"}
                onClick={this.props.onCriseToggle}
              >
                <ModeCrise className={this.props.toggleCrise ? "btnModeCriseTrue" : "btnModeCriseFalse"}/>
                <span>
                                    {this.props.toggleCrise ? "Close crisis management mode" : "Open crisis management mode"}
                                </span>
                <FontAwesomeIcon id="arrowUp" icon={faArrowUp}
                                 style={{display: this.props.toggleCrise ? 'block' : 'none'}}/>
                <FontAwesomeIcon id="arrowDown" icon={faArrowDown}
                                 style={{display: this.props.toggleCrise ? 'none' : 'block'}}/>
              </button>
              <button
                id="btnManualMode"
                // className={this.props.toggleCrise ? "btnCriseTrue" : "btnCriseFalse"}
                onClick={this.props.onManualModeToggle}
              >
                                <span>
                                    Freestyle mode
                                </span>
              </button>
              <div id="div-manual" style={{display: this.props.toggleManualMode ? 'block' : 'none'}}/>
            </div>
            <div className="header_actions" style={{display: this.props.toggleCrise ? 'block' : 'none'}}>
              {/*<button*/}
              {/*  id="btn_2019"*/}
              {/*  onClick={this.updateSliderValue}*/}
              {/*  value={this.props.btn2019}*/}
              {/*>*/}
              {/*  {this.props.criseOptionsLabel.btn2019}*/}
              {/*</button>*/}
              <button
                id="btn_hier"
                onClick={this.updateSliderValue}
                value={this.props.setBtnHier}
                data={btnHier}
                disabled={this.props.setBtnHier === -1}
                style={this.props.setActiveBouton.hier ? {
                  'border': "2px solid #45847a",
                  'backgroundColor': this.SetColor(btnHier)
                } : {'backgroundColor': this.SetColor(btnReel)}}
                // style={{'backgroundColor': this.SetColor(btnHier)}}
              >
                {boutonsArray[0]}
                {/*{this.props.criseOptionsLabel.btnHier}*/}
                {/*<br/> <span className='span-debit'>&lt; 5000 m³/s</span>*/}
                <br/> <span className='span-debit'>{btnHier} m³/s</span>
              </button>
              <button
                id="btn_temps_reel"
                onClick={this.updateRealTime}
                // onClick={this.updateSliderValue}
                value={this.props.setBtnReel}
                data={btnReel}
                disabled={this.props.setBtnReel === -1}
                style={this.props.setActiveBouton.reel ? {
                  'border': "2px solid #45847a",
                  'backgroundColor': this.SetColor(btnReel)
                } : {'backgroundColor': this.SetColor(btnReel)}}
                // style={{'backgroundColor': this.SetColor(btnReel)}}
              >
                Real time
                <br/> <span className="span-debit">{btnReel} m³/s</span>
              </button>
              <button
                id="btn_j1"
                // className={this.props.typeMesureDebit ? 'selected' : 'unselected'}
                onClick={this.updateSliderValue}
                value={this.props.setBtnJ1}
                data={btnJ1}
                disabled={this.props.setBtnJ1 === -1}
                style={this.props.setActiveBouton.j1 ? {
                  'border': "2px solid #45847a",
                  'backgroundColor': this.SetColor(btnJ1)
                } : {'backgroundColor': this.SetColor(btnJ1)}}
                // style={this.props.typeMesureDebit ? {'border': "5px solid #F9DDD2"} : {'backgroundColor': this.SetColor(btnJ1)}}
              >
                {boutonsArray[1]}
                {/*{this.props.criseOptionsLabel.btnJ1}*/}
                <br/> <span className="span-debit">{btnJ1} m³/s</span>
              </button>
              <button
                id="btn_j2"
                onClick={this.updateSliderValue}
                value={this.props.setBtnJ2}
                data={btnJ2}
                disabled={this.props.setBtnJ2 === -1}
                style={this.props.setActiveBouton.j2 ? {
                  'border': "2px solid #45847a",
                  'backgroundColor': this.SetColor(btnJ2)
                } : {'backgroundColor': this.SetColor(btnJ2)}}
                // style={{'backgroundColor': this.SetColor(btnJ2)}}
              >
                {boutonsArray[2]}
                {/*{this.props.criseOptionsLabel.btnJ2}*/}
                <br/> <span className="span-debit">{btnJ2} m³/s</span>
              </button>
              <button
                id="btn_j3"
                onClick={this.updateSliderValue}
                value={this.props.setBtnJ3}
                data={btnJ3}
                disabled={this.props.setBtnJ3 === -1}
                style={this.props.setActiveBouton.j3 ? {
                  'border': "2px solid #45847a",
                  'backgroundColor': this.SetColor(btnJ3)
                } : {'backgroundColor': this.SetColor(btnJ3)}}                // style={{'backgroundColor': this.SetColor(btnJ3)}}
              >
                {boutonsArray[3]}
                {/*{this.props.criseOptionsLabel.btnJ3}*/}
                <br/> <span className="span-debit">{btnJ3} m³/s</span>
              </button>
              <button
                id="btn_j4"
                onClick={this.updateSliderValue}
                value={this.props.setBtnJ4}
                data={btnJ4}
                disabled={this.props.setBtnJ4 === -1}
                style={this.props.setActiveBouton.j4 ? {
                  'border': "2px solid #45847a",
                  'backgroundColor': this.SetColor(btnJ4)
                } : {'backgroundColor': this.SetColor(btnJ4)}}
                // style={{'backgroundColor': this.SetColor(btnJ4)}}
              >
                {boutonsArray[4]}
                {/*{this.props.criseOptionsLabel.btnJ4}*/}
                <br/> <span className="span-debit">{btnJ4} m³/s</span>
              </button>
              <button
                id="btn_j5"
                onClick={this.updateSliderValue}
                value={this.props.setBtnJ5}
                data={btnJ5}
                disabled={this.props.setBtnJ5 === -1}
                style={this.props.setActiveBouton.j5 ? {
                  'border': "2px solid #45847a",
                  'backgroundColor': this.SetColor(btnJ5)
                } : {'backgroundColor': this.SetColor(btnJ5)}}
                // style={{'backgroundColor': this.SetColor(btnJ5)}}
              >
                {boutonsArray[5]}
                {/*{this.props.criseOptionsLabel.btnJ5}*/}
                <br/> <span className="span-debit">{btnJ5} m³/s</span>
              </button>
              <button
                id="btn_j6"
                onClick={this.updateSliderValue}
                value={this.props.setBtnJ6}
                data={btnJ6}
                disabled={this.props.setBtnJ6 === -1}
                style={this.props.setActiveBouton.j6 ? {
                  'border': "2px solid #45847a",
                  'backgroundColor': this.SetColor(btnJ6)
                } : {'backgroundColor': this.SetColor(btnJ6)}}
                // style={{'backgroundColor': this.SetColor(btnJ6)}}
              >
                {boutonsArray[6]}
                {/*{this.props.criseOptionsLabel.btnJ6}*/}
                <br/> <span className="span-debit">{btnJ6} m³/s</span>
              </button>
              <button
                id="btn_j7"
                onClick={this.updateSliderValue}
                value={this.props.setBtnJ7}
                data={btnJ7}
                disabled={this.props.setBtnJ7 === -1}
                style={this.props.setActiveBouton.j7 ? {
                  'border': "2px solid #45847a",
                  'backgroundColor': this.SetColor(btnJ7)
                } : {'backgroundColor': this.SetColor(btnJ7)}}
                // style={{'backgroundColor': this.SetColor(btnJ7)}}
              >
                {boutonsArray[7]}
                {/*{this.props.criseOptionsLabel.btnJ7}*/}
                <br/> <span className="span-debit">{btnJ7} m³/s</span>
              </button>
              {/*<span className="last-update">Mise à jour: {lastUpdate}</span>*/}
            </div>
          </div>
        </div>
        <div>
          <button className="btn_signout" onClick={this.props.signOut}>
            {" "}
            <FontAwesomeIcon icon={faPowerOff}/>
          </button>
        </div>
      </header>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    typeMesureDebit: state.debit,
    criseOptionsLabel: state.criseOptionsLabel,
    toggleCrise: state.crise,
    toggleManualMode: state.manualMode,
    setApp: state.app,
    setBtnHier: state.boutonHier,
    setBtnReel: state.boutonReel,
    setBtnJ1: state.boutonJ1,
    setBtnJ2: state.boutonJ2,
    setBtnJ3: state.boutonJ3,
    setBtnJ4: state.boutonJ4,
    setBtnJ5: state.boutonJ5,
    setBtnJ6: state.boutonJ6,
    setBtnJ7: state.boutonJ7,
    setValueHier: state.valueHier,
    setValueReel: state.valueReel,
    setValueJ1: state.valueJ1,
    setValueJ2: state.valueJ2,
    setValueJ3: state.valueJ3,
    setValueJ4: state.valueJ4,
    setValueJ5: state.valueJ5,
    setValueJ6: state.valueJ6,
    setValueJ7: state.valueJ7,
    setActiveBouton: state.activeBouton,
    // setLastUpdate: state.lastUpdate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCriseToggle: () => dispatch(actionCreators.toggleCrise()),
    onManualModeToggle: () => dispatch(actionCreators.toggleManualMode()),
    onSetBtnHier: (id) => dispatch(actionCreators.setBtnHier(id)),
    onSetBtnReel: (id) => dispatch(actionCreators.setBtnReel(id)),
    onSetBtnJ1: (id) => dispatch(actionCreators.setBtnJ1(id)),
    onSetBtnJ2: (id) => dispatch(actionCreators.setBtnJ2(id)),
    onSetBtnJ3: (id) => dispatch(actionCreators.setBtnJ3(id)),
    onSetBtnJ4: (id) => dispatch(actionCreators.setBtnJ4(id)),
    onSetBtnJ5: (id) => dispatch(actionCreators.setBtnJ5(id)),
    onSetBtnJ6: (id) => dispatch(actionCreators.setBtnJ6(id)),
    onSetBtnJ7: (id) => dispatch(actionCreators.setBtnJ7(id)),
    onSetValueHier: (val) => dispatch(actionCreators.setValueHier(val)),
    onSetValueReel: (val) => dispatch(actionCreators.setValueReel(val)),
    onSetValueJ1: (val) => dispatch(actionCreators.setValueJ1(val)),
    onSetValueJ2: (val) => dispatch(actionCreators.setValueJ2(val)),
    onSetValueJ3: (val) => dispatch(actionCreators.setValueJ3(val)),
    onSetValueJ4: (val) => dispatch(actionCreators.setValueJ4(val)),
    onSetValueJ5: (val) => dispatch(actionCreators.setValueJ5(val)),
    onSetValueJ6: (val) => dispatch(actionCreators.setValueJ6(val)),
    onSetValueJ7: (val) => dispatch(actionCreators.setValueJ7(val)),
    onActiveBouton: (bouton) => dispatch(actionCreators.setActiveBouton(bouton)),
    onSetLastUpdate: (txt) => dispatch(actionCreators.setLastUpdate(txt)),
    onTempsReelToggle: (eta) => dispatch(actionCreators.toggleTempsReel(eta))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
// export default Header;
