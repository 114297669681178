import React, {useState} from "react";
import "./Mentions.css";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";


export const Mentions = () => {
  const [mentionsState, mentionsSetState] = useState({
    toggleMentions: false
  });
  const mentionsToggle = () => {
    mentionsSetState({
      toggleMentions: !mentionsState.toggleMentions
    })
  };
  const lastUpdate = useSelector(state => state.lastUpdate);
  const app = useSelector(state => state.app);
  let mrc;
  if (app === "RI_M") {
    mrc = "Vaudreuil-Soulange"
  } else if (app === "SA_M") {
    mrc = "d'Argenteuil"
  }

  return (
    <>
      <button id="btn-toggle-mentions" onClick={mentionsToggle}>
        <FontAwesomeIcon icon={faInfoCircle} size="2x"
                         color={mentionsState.toggleMentions ? "var(--color-cyan)" : "var(--color-bg-stats)"}/>
      </button>

      <div id="div-mentions" style={{display: mentionsState.toggleMentions ? 'block' : 'none'}}>
        <p>Les jeux de données « LIDAR – Modèles numériques (terrain, canopée, pente) » et « Adresses Québec » utilisés
          par Géosapiens sont mis à disposition par <a href="https://www.donneesquebec.ca/fr/" rel="noopener noreferrer"
                                                       target="_blank">Données Québec</a> sous licence <a
            href="https://creativecommons.org/licenses/by/4.0/legalcode.fr" rel="noopener noreferrer" target="_blank">CC
            Attribution (BY 4.0)</a> et leur version originale est disponible <a href="https://www.donneesquebec.ca/fr/"
                                                                                 rel="noopener noreferrer"
                                                                                 target="_blank">ici</a>. Géosapiens
          peut superposer des informations sur les données issues du jeu de données « Adresses Québec » pour permettre
          le rendu de ses services.</p>
        <p>La mosaïque a été produites à partir des photos aériennes acquises lors des inondations de 2019 et rendues
          disponibles par la Communauté métropolitaine de Montréal (CMM). Les photos sont disponibles <a
            href="https://sigma.cmm.qc.ca/application/run/407/embedded"
            rel="noopener noreferrer"
            target="_blank">ici</a>.</p>
        <p>Les données des bâtiments proviennent de la MRC {mrc}.</p>
        <p>Les données en temps réel pour les stations de Carillon et Grenville proviennent de la <a
          href="https://rivieredesoutaouais.ca/conditions-actuelles/"
          rel="noopener noreferrer"
          target="_blank">CPRRO</a>. Les prévisions à la station de Carillon et les données en temps réel aux stations
          Baie de Rigaud, rivière Rigaud, Baie Quesnel et STAA proviennent d'<a
            href="https://www.hydrometeo.net/"
            rel="noopener noreferrer"
            target="_blank">Hydro Météo</a>.</p>
        <p>Dernière mise à jour des données en temps réel: {lastUpdate}</p>
        <p>Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a
          href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
      </div>
      <div id="copyright">
        <p>© 2022 Geosapiens</p>
      </div>
    </>
  );
};