export const SET_APP = 'SET_APP';
export const SET_BTN_HIER = 'SET_BTN_HIER';
export const SET_BTN_REEL = 'SET_BTN_REEL';
export const SET_BTN_J1 = 'SET_BTN_J1';
export const SET_BTN_J2 = 'SET_BTN_J2';
export const SET_BTN_J3 = 'SET_BTN_J3';
export const SET_BTN_J4 = 'SET_BTN_J4';
export const SET_BTN_J5 = 'SET_BTN_J5';
export const SET_BTN_J6 = 'SET_BTN_J6';
export const SET_BTN_J7 = 'SET_BTN_J7';
export const SET_VALUE_HIER = 'SET_VALUE_HIER';
export const SET_VALUE_REEL = 'SET_VALUE_REEL';
export const SET_VALUE_J1 = 'SET_VALUE_J1';
export const SET_VALUE_J2 = 'SET_VALUE_J2';
export const SET_VALUE_J3 = 'SET_VALUE_J3';
export const SET_VALUE_J4 = 'SET_VALUE_J4';
export const SET_VALUE_J5 = 'SET_VALUE_J5';
export const SET_VALUE_J6 = 'SET_VALUE_J6';
export const SET_VALUE_J7 = 'SET_VALUE_J7';
export const ACTIVE_BOUTON = 'ACTIVE_BOUTON';
export const CHANGE_SCENARIO = 'CHANGE_SCENARIO';
export const TOGGLE_WATER = 'TOGGLE_WATER';
export const TOGGLE_BUILDING = 'TOGGLE_BUILDING';
export const TOGGLE_STREET = 'TOGGLE_STREET';
export const TOGGLE_BAG = 'TOGGLE_BAG';
export const TOGGLE_SERVICE = 'TOGGLE_SERVICE';
export const TOGGLE_STATION = 'TOGGLE_STATION';
export const TOGGLE_PHOTO = 'TOGGLE_PHOTO';
export const TOGGLE_ZI_2019 = 'TOGGLE_ZI_2019';
export const TOGGLE_ZI_2 = 'TOGGLE_ZI_2';
export const TOGGLE_ZI_20 = 'TOGGLE_ZI_20';
export const TOGGLE_ZI_100 = 'TOGGLE_ZI_100';
export const TOGGLE_STATS = 'TOGGLE_STATS';
export const TOGGLE_CRISE = 'TOGGLE_CRISE';
export const TOGGLE_MANUAL_MODE = 'TOGGLE_MANUAL_MODE';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const TOGGLE_ALERTE = 'TOGGLE_ALERTE';
export const TOGGLE_VALIDATION = 'TOGGLE_VALIDATION';
export const TOGGLE_GRAPH = 'TOGGLE_GRAPH';
export const CHANGE_TYPE_MESURE = 'CHANGE_TYPE_MESURE';  //Pour le app en general
export const CHANGE_MATCH_MEDIA = 'CHANGE_MATCH_MEDIA';
export const CHANGE_DATA_EXPORT = 'CHANGE_DATA_EXPORT';
export const SET_LAST_UPDATE = 'SET_LAST_UPDATE';
export const TOGGLE_TEMPS_REEL = 'TOGGLE_TEMPS_REEL';

export const setApp = (id) => {
  return {
    type: SET_APP,
    appId: id
  };
};
export const setBtnHier = (id) => {
  return {
    type: SET_BTN_HIER,
    btnHierId: id
  };
};
export const setBtnReel = (id) => {
  return {
    type: SET_BTN_REEL,
    btnReelId: id
  };
};
export const setBtnJ1 = (id) => {
  return {
    type: SET_BTN_J1,
    btnJ1Id: id
  };
};
export const setBtnJ2 = (id) => {
  return {
    type: SET_BTN_J2,
    btnJ2Id: id
  };
};
export const setBtnJ3 = (id) => {
  return {
    type: SET_BTN_J3,
    btnJ3Id: id
  };
};
export const setBtnJ4 = (id) => {
  return {
    type: SET_BTN_J4,
    btnJ4Id: id
  };
};
export const setBtnJ5 = (id) => {
  return {
    type: SET_BTN_J5,
    btnJ5Id: id
  };
};
export const setBtnJ6 = (id) => {
  return {
    type: SET_BTN_J6,
    btnJ6Id: id
  };
};
export const setBtnJ7 = (id) => {
  return {
    type: SET_BTN_J7,
    btnJ7Id: id
  };
};
export const setValueHier = (val) => {
  return {
    type: SET_VALUE_HIER,
    btnHierVal: val
  };
};
export const setValueReel = (val) => {
  return {
    type: SET_VALUE_REEL,
    btnReelVal: val
  };
};
export const setValueJ1 = (val) => {
  return {
    type: SET_VALUE_J1,
    btnJ1Val: val
  };
};
export const setValueJ2 = (val) => {
  return {
    type: SET_VALUE_J2,
    btnJ2Val: val
  };
};
export const setValueJ3 = (val) => {
  return {
    type: SET_VALUE_J3,
    btnJ3Val: val
  };
};
export const setValueJ4 = (val) => {
  return {
    type: SET_VALUE_J4,
    btnJ4Val: val
  };
};
export const setValueJ5 = (val) => {
  return {
    type: SET_VALUE_J5,
    btnJ5Val: val
  };
};
export const setValueJ6 = (val) => {
  return {
    type: SET_VALUE_J6,
    btnJ6Val: val
  };
};
export const setValueJ7 = (val) => {
  return {
    type: SET_VALUE_J7,
    btnJ7Val: val
  };
};
export const setActiveBouton = (bouton) => {
  let hier, reel, j1, j2, j3, j4, j5, j6, j7 = false;
  if (bouton === "btn_hier") {
    hier = true
  }
  if (bouton === "btn_temps_reel") {
    reel = true
  }
  if (bouton === "btn_j1") {
    j1 = true
  }
  if (bouton === "btn_j2") {
    j2 = true
  }
  if (bouton === "btn_j3") {
    j3 = true
  }
  if (bouton === "btn_j4") {
    j4 = true
  }
  if (bouton === "btn_j5") {
    j5 = true
  }
  if (bouton === "btn_j6") {
    j6 = true
  }
  if (bouton === "btn_j7") {
    j7 = true
  }
  return {
    type: ACTIVE_BOUTON,
    boutonActive: {
      hier: hier,
      reel: reel,
      j1: j1,
      j2: j2,
      j3: j3,
      j4: j4,
      j5: j5,
      j6: j6,
      j7: j7
    }
  };
};
export const changeScenario = (id) => {
 
  return {
    type: CHANGE_SCENARIO,
    scenarioId: id
  };
};
export const toggleWater = () => {
  return {
    type: TOGGLE_WATER,
  };
};
export const toggleBuilding = () => {
  return {
    type: TOGGLE_BUILDING,
  };
};
export const toggleStreet = () => {
  return {
    type: TOGGLE_STREET,
  };
};
export const toggleBag = () => {
  return {
    type: TOGGLE_BAG,
  };
};

export const toggleService = () => {
  return {
    type: TOGGLE_SERVICE,
  };
};

export const toggleStation = () => {
  return {
    type: TOGGLE_STATION,
  };
};

export const togglePhoto = () => {
  return {
    type: TOGGLE_PHOTO,
  };
};

export const toggleZi2019 = () => {
  return {
    type: TOGGLE_ZI_2019,
  };
};

export const toggleZi2 = () => {
  return {
    type: TOGGLE_ZI_2,
  };
};

export const toggleZi20 = () => {
  return {
    type: TOGGLE_ZI_20,
  };
};

export const toggleZi100 = () => {
  return {
    type: TOGGLE_ZI_100,
  };
};

export const toggleStats = () => {
  return {
    type: TOGGLE_STATS,
  };
};

export const toggleCrise = () => {
  return {
    type: TOGGLE_CRISE,
  };
};

export const toggleManualMode = () => {
  return {
    type: TOGGLE_MANUAL_MODE,
  };
};

export const toggleSearch = () => {
  return {
    type: TOGGLE_SEARCH,
  };
};

export const toggleAlerte = () => {
  return {
    type: TOGGLE_ALERTE,
  };
};

export const toggleValidation = () => {
  return {
    type: TOGGLE_VALIDATION,
  };
};

export const toggleGraph = (activeGraph) => {
  return {
    type: TOGGLE_GRAPH,
    newActiveGraph: activeGraph
  };
};

export const changeTypeMesure = () => {
  return {
    type: CHANGE_TYPE_MESURE
  };
};

export const matchMediaChange = (mq) => {
  return {
    type: CHANGE_MATCH_MEDIA,
    widthMatches: mq.matches
  };
};

export const changeDataExport = (data) => {
  return {
    type: CHANGE_DATA_EXPORT,
    newData: data
  };
};

export const setLastUpdate = (txt) => {
  return {
    type: SET_LAST_UPDATE,
    updateTxt: txt
  };
};

export const toggleTempsReel = (eta) => {
  return {
    type: TOGGLE_TEMPS_REEL,
    etat: eta
  };
};
// EXEMPLE ASYNC
// export const saveStatsEvac = (count) => {
//     return {
//         type: CHANGE_STATS_EVAC,
//         statsCount: count
//     };
// };
//
// export const storeStatsEvac = (count) => {
//     return dispatch => {
//         setTimeout(() => {
//             dispatch(saveStatsEvac(count));
//         }, 2000);
//     }
// };
