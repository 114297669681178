import * as actionCreators from './actions';
import {updateObject} from './utility';
// import {SET_BTN3J} from "./actions";


const initialState = {
  app: "",
  boutonHier: -1,
  boutonReel: -1,
  boutonJ1: -1,
  boutonJ2: -1,
  boutonJ3: -1,
  boutonJ4: -1,
  boutonJ5: -1,
  boutonJ6: -1,
  boutonJ7: -1,
  valueHier: -1,
  valueReel: -1,
  valueJ1: -1,
  valueJ2: -1,
  valueJ3: -1,
  valueJ4: -1,
  valueJ5: -1,
  valueJ6: -1,
  valueJ7: -1,
  activeBouton: {
    hier: false,
    reel: false,
    j1: false,
    j2: false,
    j3: false,
    j4: false,
    j5: false,
    j6: false,
    j7: false
  },
  scenario: 0,
  water: true,
  building: false,
  street: false,
  bag: false,
  service: false,
  station: false,
  photo: false,
  zi2019: false,
  zi2: false,
  zi20: false,
  zi100: false,
  stats: true,
  crise: false,
  // manualMode: true,
  manualMode: false,
  // search: true,
  search: false,
  alerte: false,
  validation: false,
  graph: {
    statsIsole: false,
    statsSsInonde: false,
    statsPremInonde: false,
    statsTotalInonde: false,
    statsNbEvac: false
  },
  debit: true,
  criseOptionsLabel: {
    rec: "Recurrence",
    annees: "in years",
    // btn2019: "2019",
    // btnHier: "Situation hier",
    // btnTempsReel: "Temps réel",
    // btnJ1: "Aujourd'hui",
    // btnJ2: "J+1",
    // btnJ3: "J+2",
    // btnJ4: "J+3",
    // btnJ5: "J+4",
    // btnJ6: "J+5",
    // btnJ7: "J+6"
  },
  dataExport: [],
  lastUpdate: "",
  tempsReel: false
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.SET_APP:
      return updateObject(state, {app: action.appId});
    case actionCreators.SET_BTN_HIER:
      return updateObject(state, {boutonHier: typeof action.btnHierId === 'undefined' ? 0 : action.btnHierId});
    case actionCreators.SET_BTN_REEL:
      return updateObject(state, {boutonReel: typeof action.btnReelId === 'undefined' ? 0 : action.btnReelId});
    case actionCreators.SET_BTN_J1:
      return updateObject(state, {boutonJ1: typeof action.btnJ1Id === 'undefined' ? 0 : action.btnJ1Id});
    // return updateObject(state, {boutonJ1: action.btnJ1Id});
    case actionCreators.SET_BTN_J2:
      return updateObject(state, {boutonJ2: typeof action.btnJ2Id === 'undefined' ? 0 : action.btnJ2Id});
    case actionCreators.SET_BTN_J3:
      return updateObject(state, {boutonJ3: typeof action.btnJ3Id === 'undefined' ? 0 : action.btnJ3Id});
    case actionCreators.SET_BTN_J4:
      return updateObject(state, {boutonJ4: typeof action.btnJ4Id === 'undefined' ? 0 : action.btnJ4Id});
    case actionCreators.SET_BTN_J5:
      return updateObject(state, {boutonJ5: typeof action.btnJ5Id === 'undefined' ? 0 : action.btnJ5Id});
    case actionCreators.SET_BTN_J6:
      return updateObject(state, {boutonJ6: typeof action.btnJ6Id === 'undefined' ? 0 : action.btnJ6Id});
    case actionCreators.SET_BTN_J7:
      return updateObject(state, {boutonJ7: typeof action.btnJ7Id === 'undefined' ? 0 : action.btnJ7Id});
    case actionCreators.SET_VALUE_HIER:
      return updateObject(state, {valueHier: action.btnHierVal});
    case actionCreators.SET_VALUE_REEL:
      return updateObject(state, {valueReel: action.btnReelVal});
    case actionCreators.SET_VALUE_J1:
      return updateObject(state, {valueJ1: action.btnJ1Val});
    case actionCreators.SET_VALUE_J2:
      return updateObject(state, {valueJ2: action.btnJ2Val});
    case actionCreators.SET_VALUE_J3:
      return updateObject(state, {valueJ3: action.btnJ3Val});
    case actionCreators.SET_VALUE_J4:
      return updateObject(state, {valueJ4: action.btnJ4Val});
    case actionCreators.SET_VALUE_J5:
      return updateObject(state, {valueJ5: action.btnJ5Val});
    case actionCreators.SET_VALUE_J6:
      return updateObject(state, {valueJ6: action.btnJ6Val});
    case actionCreators.SET_VALUE_J7:
      return updateObject(state, {valueJ7: action.btnJ7Val});
    case actionCreators.ACTIVE_BOUTON:
      return updateObject(state, {activeBouton: action.boutonActive});
    case actionCreators.CHANGE_SCENARIO:
      return updateObject(state, {scenario: action.scenarioId});
    case actionCreators.TOGGLE_WATER:
      return updateObject(state, {water: !state.water});
    case actionCreators.TOGGLE_BUILDING:
      return updateObject(state, {building: !state.building});
    case actionCreators.TOGGLE_STREET:
      return updateObject(state, {street: !state.street});
    case actionCreators.TOGGLE_BAG:
      return updateObject(state, {bag: !state.bag});
    case actionCreators.TOGGLE_SERVICE:
      return updateObject(state, {service: !state.service});
    case actionCreators.TOGGLE_STATION:
      return updateObject(state, {station: !state.station});
    case actionCreators.TOGGLE_PHOTO:
      return updateObject(state, {photo: !state.photo});
    case actionCreators.TOGGLE_ZI_2019:
      return updateObject(state, {zi2019: !state.zi2019, zi2: false, zi20: false, zi100: false});
    case actionCreators.TOGGLE_ZI_2:
      return updateObject(state, {zi2: !state.zi2, zi20: false, zi100: false, zi2019: false});
    case actionCreators.TOGGLE_ZI_20:
      return updateObject(state, {zi20: !state.zi20, zi2: false, zi100: false, zi2019: false});
    case actionCreators.TOGGLE_ZI_100:
      return updateObject(state, {zi100: !state.zi100, zi2: false, zi20: false, zi2019: false});
    case actionCreators.TOGGLE_STATS:
      return updateObject(state, {stats: !state.stats});
    case actionCreators.TOGGLE_CRISE:
      return updateObject(state, {crise: !state.crise});
    case actionCreators.TOGGLE_MANUAL_MODE:
      return updateObject(state, {manualMode: !state.manualMode, stats: state.manualMode});
    case actionCreators.TOGGLE_SEARCH:
      return updateObject(state, {search: !state.search});
    case actionCreators.TOGGLE_ALERTE:
      return updateObject(state, {alerte: !state.alerte});
    case actionCreators.TOGGLE_VALIDATION:
      return updateObject(state, {validation: !state.validation});
    case actionCreators.TOGGLE_GRAPH:
      let newState = {
        statsKm: false,
        statsSacs: false,
        statsIsole: false,
        // statsSsInonde: false,
        // statsPremInonde: false,
        statsTotalInonde: false,
        statsNbEvac: false
      };
      if (action.newActiveGraph === "statsKm") {
        newState = {
          ...newState,
          statsKm: !state.graph.statsKm
        }
      }
      if (action.newActiveGraph === "statsSacs") {
        newState = {
          ...newState,
          statsSacs: !state.graph.statsSacs
        }
      }
      if (action.newActiveGraph === "statsIsole") {
        newState = {
          ...newState,
          statsIsole: !state.graph.statsIsole
        }
      }
      // if (action.newActiveGraph === "statsSsInonde") {
      //   newState = {
      //     ...newState,
      //     statsSsInonde: !state.graph.statsSsInonde
      //   }
      // }
      // if (action.newActiveGraph === "statsPremInonde") {
      //   newState = {
      //     ...newState,
      //     statsPremInonde: !state.graph.statsPremInonde
      //   }
      // }
      if (action.newActiveGraph === "statsTotalInonde") {
        newState = {
          ...newState,
          statsTotalInonde: !state.graph.statsTotalInonde
        }
      }
      if (action.newActiveGraph === "statsNbEvac") {
        newState = {
          ...newState,
          statsNbEvac: !state.graph.statsNbEvac
        }
      }
      return updateObject(state, {graph: newState});

    case actionCreators.CHANGE_TYPE_MESURE:
      return updateObject(state, {debit: !state.debit});
    case actionCreators.CHANGE_MATCH_MEDIA:
      return updateObject(state, changeWidth(action.widthMatches));
    case actionCreators.CHANGE_DATA_EXPORT:
      return updateObject(state, {dataExport: action.newData});
    case actionCreators.SET_LAST_UPDATE:
      return updateObject(state, {lastUpdate: action.updateTxt});
    case actionCreators.TOGGLE_TEMPS_REEL:
      return updateObject(state, {tempsReel: action.etat === "off" ? false : true});
    default:
      return state;
  }
};

const changeWidth = (widthMatches) => {
  if (widthMatches) {
    return {
      criseOptionsLabel: {
        rec: "",
        annees: "",
        // btn2019: "2019",
        // btnHier: "Hier",
        // btnTempsReel: "Temps réel",
        // btnJ1: "Aujourd'hui",
        // btnJ2: "J+1",
        // btnJ3: "J+2",
        // btnJ4: "J+3",
        // btnJ5: "J+4",
        // btnJ6: "J+5",
        // btnJ7: "J+6"
      },
    };
  }
  return {
    criseOptionsLabel: {
      rec: "Recurrence",
      annees: "in years",
      // btn2019: "2019",
      // btnHier: "Situation hier",
      // btnTempsReel: "Temps réel",
      // btnJ1: "Aujourd'hui",
      // btnJ2: "J+1",
      // btnJ3: "J+2",
      // btnJ4: "J+3",
      // btnJ5: "J+4",
      // btnJ6: "J+5",
      // btnJ7: "J+6"
    },
  };
};

export default reducer;