import React from "react";
import {connect} from "react-redux";
import * as actionCreators from "../store/actions";
import Header from "../components/Header";
import BottomPanel from "../components/BottomPanel";
import {WaterLevelMap} from "../components/WaterLevelMap";
import {RI, RI_M, SA_M} from "../constants/appsConstants";
import {Mentions} from "../components/Mentions";
import {Legende} from "../components/Legende";
import Validation from "../components/Validation";

export class AppRigaudMunic extends React.Component {
  render() {
    this.props.onSetApp("RI_M");
    return (
      <div className="app">
        <Header
          scenarioDepart={RI_M.scenarioDepart}
          nbScenario={RI_M.nbScenario}
          labels={RI_M.labels}
          // btn2019={RI_M.btn2019}
          // btnHier={RI_M.btnHier}
          // btnTempsReel={RI_M.btnTempsReel}
          // btnUnJour={RI_M.btnUnJour}
          // btnTroisJours={RI_M.btnTroisJours}
          yearMarkers={RI_M.yearMarkers}
          signOut={this.props.auth.signOut}
        />

        <BottomPanel
          scenarioDepart={RI_M.scenarioDepart}
          labels={RI_M.labels}
          dataKm={RI_M.dataKm}
          dataSacs={RI_M.dataSacs}
          dataIsole={RI_M.dataIsole}
          // dataSS={RI_M.dataSS}
          // dataPrem={RI_M.dataPrem}
          dataTot={RI_M.dataTot}
          dataEvac={RI_M.dataEvac}
          bagUrl={RI_M.bagUrl}
          serviceEssentiel_Url={RI_M.serviceEssentiel_Url}
        />

        <WaterLevelMap
          configuration={RI_M}
          labels={RI_M.labels}
          store={this.props.store}
        />
        <Legende/>
        <Mentions/>
        <Validation/>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetApp: (id) => dispatch(actionCreators.setApp(id)),
    }
};

export default connect(null, mapDispatchToProps)(AppRigaudMunic)